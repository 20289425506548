*::-webkit-scrollbar,
*::-webkit-scrollbar-thumb {
  width: 17px;
  border-radius: 13px;
  background-clip: padding-box;
  border: 4px solid transparent;
  color: #cacdce;
}

*::-webkit-scrollbar-thumb {
  box-shadow: inset 0 0 0 5px;
}

input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus, 
input:-webkit-autofill:active{
   -webkit-box-shadow: 0 0 0 30px #fff inset !important;
}